<template>
  <div>
    <div class="actions row col-lg-12 mb-5 p-0 m-0 justify-content-end">
      <div class="actions-right row col-lg-6 row justify-content-end p-0 m-0">
        <button type="button" class="btn btn-primary" @click="addSponsor">{{ (' Add Sponsor') }}</button>
      </div>
    </div>
    <CTableWrapper ref="table" hover striped :caption="caption" :items="items" :actions="actions" :fields="fields" clickable-rows @row-clicked="rowClicked" @action-clicked="actionClicked" @update-requested="updateItemsRequested" :sorter="{external: true}" :loading="loading">
    </CTableWrapper>
  </div>
</template>
 
<script>
import { mapState, mapActions } from 'vuex'
import CTableWrapper from '../controls/PaginatedTable.vue'
import router from '@/router';
import InputGetSponsors from "@/classes/InputGetSponsors";

export default {
  components: { CTableWrapper },
  data () {
    return {
      caption: ('Sponsors'),
      fields: [
        { key: 'photo', label: ('Photo'), _classes: 'font-weight-bold', sortable: false },
        { key: 'name', label: ('Name'), _classes: 'font-weight-bold', sortable: false },
        { key: 'link', label: ('Link'), _classes: 'font-weight-bold', sortable: false },
        { key: 'level', label: ('Level'), _classes: 'font-weight-bold', sortable: false },
      ],
      actions: [
        { key: 'view', label: ('view') },
        { key: 'remove', label: ('remove') }
      ],
      loading: false
    }
  },
  computed: {
    ...mapState('sponsors', ['listOutput']),
    ...mapState('sponsors', ['removeTask']),
    sponsorsInfo: function() {
      return this.listOutput ? this.listOutput : null;
    },
    items: function()
    {
      return this.sponsorsInfo ? this.sponsorsInfo : [];
    },
  },
  mounted: function()
  {
    this.updateItemsRequested();
  },
  methods: {
    ...mapActions('sponsors', ['getList']),
    ...mapActions('sponsors', ['removeSponsors']),
    rowClicked (item, index) {
      this.$router.push({name: "EditSponsors", params: { sponsors: item } });
    },
    actionClicked(item, action){
      switch(action.key)
      {
        case 'view':
        {
          this.$router.push({name: "EditSponsors", params: { sponsors: item } });
          break;
        }
        case 'remove':
        {
          if(confirm("Wanna remove this sponsor?")){
            this.removeSponsors(item.id);
          }
          break;
        }
        default:
        {
          throw "The provided action is not supported!"; 
        }
      }
    },
    updateItemsRequested()
    {
      //Make request
      this.loading = true;

      let input = new InputGetSponsors();
      this.getList(input); 
    },
    addSponsor () 
    {
      router.push({ path: '/sponsors/add' });
    },
  },
  watch:
  {
    removeTask: function(val)
    {
      // Sponsor was deleted, reload list
      if(val)
      {
        this.updateItemsRequested();
      }
    },
    listOutput: function(val)
    {
      if(val){
        this.loading = false;
      }
    }
  }
}
</script>

<style>
th
{
  border-top: none !important;
}

tr
{
  cursor: pointer;
}

.status-button
{
  border: none;
  padding: 0;
  text-decoration: underline;
  color: #321fdb;
}
</style> 